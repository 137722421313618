import React from 'react';
import Triangle from './Triangle';
import styled,{Heading} from 'styled-components';
import ScrollIcon from './ScrollIcon';
import TextLoop from 'react-text-loop'
import ContactButton from './ContactButton';

import { NetlifyForm, Honeypot } from 'react-netlify-forms'
// import FormInputs from './form-inputs'
// import FormControls from './form-controls'
// import SuccessMessage from './success-message'
// import ErrorMessage from './error-message'
import ContactPage from '../components/form/form'


const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto' };
const SectionContainer = styled.div`
  min-height: 100vh;
  min-width: 320px;
  max-width: 1366px;
  display: flex;
  margin: auto;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  padding: 5em 1em;
  scroll-behavior: smooth;

  @media (max-width: 400px) {
    padding: 2em 1em;
    justify-content: center;
    display:flex;
  }
`;
const Label = styled.h1`
  color: #007451;
  text-align: center;
  font-size: 24px;
  z-index: 0;
  font-weight: normal;
`

const ContactRequest = () => {
  return (
      <section id="contact"  style={{ position: 'relative', marginBottom: '25px'}}>

        <div style={{display:'flex',justifyContent:'center', position:'relative',zIndex:'1', paddingBottom:'20px', paddingTop:'90px'}}>
          <div style={{border: 'solid', borderWidth: 'thin', padding: '20px'}}>
          <h1>Service Request</h1>
          <ContactPage />  
          </div>
        </div>
          
        <Triangle
          color="#FFCC29"
          height={['35vh', '80vh']}
          width={['95vw', '80vw']}
        />
    
        <Triangle
          color="#007451"
          height={['39vh', '95vh']}
          width={['50vw', '45vw']}
        />
    
        <Triangle
          color="#81B214"
          height={['25vh', '45vh']}
          width={['75vw', '60vw']}
          position="top-right"
        />
    
        <Triangle
          color="#FFCC29"
          height={['20vh', '20vh']}
          width={['100vw', '100vw']}
          position="bottom-right"
        />


          

      </section>
      

  );
};


export default ContactRequest;



