import React from 'react';
// import { Heading, Flex, Box, Text } from 'rebass/styled-components';
// import TextLoop from 'react-text-loop';
// import Section from 'gatsby-theme-mate/src/components/Section';
// import SocialLink from 'gatsby-theme-mate/src/components/SocialLink';
// import ScrollIcon from 'gatsby-theme-mate/src/components/ScrollIcon';
// import Triangle from 'gatsby-theme-mate/src/components/Triangle';
// import { useSiteQuery } from 'gatsby-theme-mate/src/queries/useSiteQuery';
// import { SECTION } from 'gatsby-theme-mate/src/utils/constants';
// import { getSectionHref } from 'gatsby-theme-mate/src/utils/helpers';
import Triangle from './Triangle';
import styled,{Heading} from 'styled-components';
import ScrollIcon from './ScrollIcon';
// import {Motion, spring, StaggeredMotion} from 'react-motion';
import TextLoop from 'react-text-loop'
// import { Heading, Flex, Box, Text } from 'rebass/styled-components';

import ContactButton from './ContactButton';


const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto' };
const SectionContainer = styled.div`
  min-height: 100vh;
  min-width: 320px;
  max-width: 1366px;
  display: flex;
  margin: auto;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  padding: 5em 1em;
  scroll-behavior: smooth;

  @media (max-width: 400px) {
    padding: 2em 1em;
    justify-content: center;
    display:flex;
  }
`;
const Label = styled.h1`
  color: #007451;
  text-align: center;
  font-size: 24px;
  z-index: 0;
  font-weight: normal;
`
// const ContactButton = styled.a`
//   border-style: solid;
//   border-width: 2px;
//   border-color: #007451;
//   color: white;
//   z-index:-1;
//   text-align: center;
//   border-radius: 15px;
//   font-size: 30px;
//   padding: 10px;
//   top: 50%;
//   text-decoration: none;
//   font-weight: bold;
//   background-color: #007451;
// `
const Home = () => {
//   const { name, roles, socialLinks, deterministic } = useSiteQuery();
  return (
      <section style={{ position: 'relative'}}>
          
        <Triangle
          color="#FFCC29"
          height={['35vh', '80vh']}
          width={['95vw', '80vw']}
        />
    
        <Triangle
          color="#007451"
          height={['39vh', '95vh']}
          width={['50vw', '45vw']}
        />
    
        <Triangle
          color="#81B214"
          height={['25vh', '45vh']}
          width={['75vw', '60vw']}
          position="top-right"
        />
    
        <Triangle
          color="#FFCC29"
          height={['20vh', '20vh']}
          width={['100vw', '100vw']}
          position="bottom-right"
        />


        <SectionContainer>
            <div style={{"textAlign":"center", "margin":"20px", "zIndex":0}}>
              <ContactButton href='#contact'>Get a Dumpster</ContactButton>
            </div>
            <ScrollIcon/>
            <Label style={{"font-size":34}}>Dumpsters Rentals</Label>
            <Label>
              <TextLoop springConfig={{ stiffness: 180, damping: 7 }}>
              <div>(929) 615-4980</div>
              <div>10, 15, 20, 30 and 40 yd dumpsters</div>
              <div>(929) 615-4980</div>
              <div>Now Serving NYC and Long Island</div>
              <div>(929) 615-4980</div>
              <div>Dumpsters for all your needs!</div>
              <div>(929) 615-4980</div>
              <div>10, 15, 20, 30 and 40 yd dumpsters</div>
              <div>(929) 615-4980</div>
              <div>Reliable Service</div>
              <div>(929) 615-4980</div>
              <div>Junk Removal</div>
              </TextLoop>
            </Label>
            <div style={{"textAlign":"center", "margin":"20px", "zIndex":0}}>
              <ContactButton href='#sizes' style={{backgroundColor:'#81b214'}}>Explore Dumpster Sizes</ContactButton>
            </div>
        </SectionContainer>
      </section>
      

  );
};


export default Home;



