import React from 'react';
import Triangle from './Triangle';
import styled,{Heading} from 'styled-components';
import ScrollIcon from './ScrollIcon';
import TextLoop from 'react-text-loop'

import yds10 from '../images/10yds.png'
import yds15 from '../images/15yds.png'
import yds20 from '../images/20yds.png'
import yds30 from '../images/30yds.png'
import yds40 from '../images/40yds.png'

const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto' };
const SectionContainer = styled.div`
  min-height: 100vh;
  min-width: 320px;
  max-width: 1366px;
  display: flex;
  margin: auto;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  padding: 5em 1em;
  scroll-behavior: smooth;

  @media (max-width: 400px) {
    padding: 2em 1em;
    justify-content: center;
    display:flex;
  }
`;
const Label = styled.h1`
  color: #007451;
  text-align: center;
  font-size: 24px;
  z-index: 0;
`
const ContactButton = styled.a`
  border-style: solid;
  border-width: 2px;
  border-color: #007451;
  color: #007451;
  z-index:-1;
  text-align: center;
  border-radius: 5px;
  font-size: 30px;
  padding: 10px;
  top: 50%;
  text-decoration: none;
`
const Card = styled.div`
  height: 200px;

`
const CardContainer =styled.div`
`

const Sizes = () => {
  return (
    <section id="sizes" style={{ position: 'relative', marginBottom: '0px'}}>
          
          <>
    <Triangle
      color="#FFCC29"
      height={['50vh', '20vh']}
      width={['50vw', '50vw']}
    />

    <Triangle
      color="#007451"
      height={['20vh', '40vh']}
      width={['75vw', '70vw']}
    />

    <Triangle
      color="#81B214"
      height={['25vh', '20vh']}
      width={['100vw', '100vw']}
      position="top-right"
    />

    <Triangle
      color="#FFCC29"
      height={['20vh', '20vh']}
      width={['100vw', '100vw']}
      position="bottom-right"
    />
        
  </>

        <SectionContainer>

            <div className='container' style={{"marginTop":"0px", zIndex:0}}>
                <div className="container">
                  <article className="content" >
                    <section className="post-full-content" style={{backgroundColor:"transparent"}} >
                      <h4 className="content-title" style={{textAlign:"center", marginTop:"10%" }}><span style={{color:"#81B214"}}>A</span>vailable Sizes</h4>
                      <section className="content-body load-external-scripts">
                        <section className="post-feed" style={{ alignSelf:"center"}}>
                          
                          <div className="post-card" style={{padding:"10px",boxShadow: "0 4px 8px 0 rgba(0,0,0,0.35)"}}>
                            <header className="post-card-header" >
                                <div style={{textAlign:"center", width:"auto", marginBottom:"10px", backgroundSize:"cover", height:"auto"}}>
                                  <img src={yds10}></img>
                                </div>
                                <div className="post-card-tags"> </div>
                                <h2 className="post-card-title">10 YDS</h2>

                            </header>
                            <section style={{fontSize:"inherit", color:"#48ae00"}} className="post-card-excerpt">Average Price Range: $240 - $500</section>
                            <footer className="post-card-footer">
                                <div className="post-card-footer-left">
                                    <span>6 feet x 12 feet x 4 feet</span>
                                </div>
                            </footer>
                          </div>

                          <div className="post-card" style={{padding:"10px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.35)"}}>
                            <header className="post-card-header">
                                <div style={{textAlign:"center", width:"auto", marginBottom:"10px", backgroundSize:"cover", height:"auto"}} >
                                <img src={yds15}></img>
                                </div>
                                <div className="post-card-tags"> </div>
                                <h2 className="post-card-title">15 YDS</h2>
                            </header>
                            <section style={{fontSize:"inherit", color:"#48ae00"}} className="post-card-excerpt">Average Price Range: $260 - $550 </section>
                            <footer className="post-card-footer">
                                <div className="post-card-footer-left">
                                    <span>7.5 feet x 16 feet x 4 feet</span>
                                </div>
                            </footer>
                          </div>

                          <div className="post-card" style={{padding:"10px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.35)"}}>
                            <header className="post-card-header">
                                <div style={{textAlign:"center", width:"auto", marginBottom:"10px", backgroundSize:"cover", height:"auto"}} >
                                <img src={yds20}></img>
                                </div>
                                <div className="post-card-tags"> </div>
                                <h2 className="post-card-title">20 YDS</h2>
                            </header>
                            <section style={{fontSize:"inherit", color:"#48ae00"}} className="post-card-excerpt">Average Price Range: $275 - $600</section>
                            <footer className="post-card-footer">
                                <div className="post-card-footer-left">
                                    <span>7.5 feet x 20 feet x 4 feet</span>
                                </div>
                            </footer>
                          </div>
                          
                          <div className="post-card" style={{padding:"10px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.35)"}}>
                            <header className="post-card-header">
                                <div style={{textAlign:"center", width:"auto", marginBottom:"10px", backgroundSize:"cover", height:"auto"}} >
                                <img src={yds30}></img>
                                </div>
                                <div className="post-card-tags"> </div>
                                <h2 className="post-card-title">30 YDS</h2>
                            </header>
                            <section style={{fontSize:"inherit", color:"#48ae00"}} className="post-card-excerpt">Average Price Range: $370 - $850</section>
                            <footer className="post-card-footer">
                                <div className="post-card-footer-left">
                                    <span>7.5 feet x 22 feet x 6 feet</span>
                                </div>
                            </footer>
                          </div>

                          <div className="post-card" style={{padding:"10px",boxShadow: "0 4px 8px 0 rgba(0,0,0,0.35)"}}>
                            <header className="post-card-header">
                                <div style={{textAlign:"center", width:"auto", marginBottom:"10px", backgroundSize:"cover", height:"auto"}}>
                                <img src={yds40}></img>
                                </div>
                                <div className="post-card-tags"> </div>
                                <h2 className="post-card-title">40 YDS</h2>
                            </header>
                            <section style={{fontSize:"inherit", color:"#48ae00"}} className="post-card-excerpt">Average Price Range: $410 - $990</section>
                            <footer className="post-card-footer">
                                <div className="post-card-footer-left">
                                    <span>7.5 feet x 22 feet x 8 feet</span>
                                </div>
                            </footer>
                          </div>
                          


                          
                        </section>
                      </section>
                    </section>
                  </article>
                </div>
            </div>
        </SectionContainer>
      </section>
      
  );
};


export default Sizes;



