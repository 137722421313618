import React from 'react'
import { Button } from 'theme-ui'

const FormControls = () => <div><br></br><Button css={{
    padding: '1rem',
    backgroundColor: '#ff3636',
    color: '#FFFFFF',
    borderRadius: '1rem',
    textDecoration: 'none',
}} type='submit'>Get a Quote</Button></div>

export default FormControls
