import React from 'react';
import Triangle from './Triangle';
import styled,{Heading} from 'styled-components';

import p2 from '../images/p2.png'
import p3 from '../images/p3.png'
import p4 from '../images/p4.png'
import p5 from '../images/p5.png'


const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto' };
const SectionContainer = styled.div`
  min-height: 100vh;
  min-width: 320px;
  max-width: 1366px;
  display: flex;
  margin: auto;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  padding: 5em 1em;
  scroll-behavior: smooth;

  @media (max-width: 400px) {
    padding: 2em 1em;
    justify-content: center;
    display:flex;
  }
`;
const Label = styled.h1`
  color: #007451;
  text-align: center;
  font-size: 24px;
  z-index: 0;
`
const ContactButton = styled.a`
  border-style: solid;
  border-width: 2px;
  border-color: #007451;
  color: #007451;
  z-index:-1;
  text-align: center;
  border-radius: 5px;
  font-size: 30px;
  padding: 10px;
  top: 50%;
  text-decoration: none;
`
const Card = styled.div`
  height: 200px;

`
const CardContainer =styled.div`
`

const Types = () => {
  return (
      <section id="services" style={{ position: 'relative', marginBottom: '0px'}}>
          
          <>
    <Triangle
      color="#FFCC29"
      height={['50vh', '20vh']}
      width={['50vw', '50vw']}
    />

    <Triangle
      color="#007451"
      height={['20vh', '40vh']}
      width={['75vw', '70vw']}
    />

    <Triangle
      color="#81B214"
      height={['25vh', '20vh']}
      width={['100vw', '100vw']}
      position="top-right"
    />

    <Triangle
      color="#FFCC29"
      height={['20vh', '20vh']}
      width={['100vw', '100vw']}
      position="bottom-right"
    />
        
  </>

        <SectionContainer>

            <div className='container' style={{"marginTop":"0px", zIndex:0}}>
                <div className="container">
                  <article className="content" >
                    <section className="post-full-content" style={{backgroundColor:"transparent"}} >
                      <h4 className="content-title"><span style={{color:"#81B214"}}>D</span>umpsters for all your needs:</h4>
                      <section className="content-body load-external-scripts">
                        <section className="waste-types" style={{}}>
                          
                          <div className="post-card" style={{padding:"10px",boxShadow: "0 4px 8px 0 rgba(0,0,0,0.35)"}}>
                            <header className="post-card-header">
                                <div style={{textAlign:"center", width:"auto", marginBottom:"10px", backgroundSize:"cover", height:""}}>
                                  <img src={p2}></img>
                                </div>
                                <div className="post-card-tags"> </div>
                                <h2 className="post-card-title">HOUSEHOLD WASTE</h2>
                            </header>
                            <section style={{fontSize:"inherit"}} className="post-card-excerpt">Rent a dumpster for your Home. Perfect for Renovations, Yard Work and Cleanouts. Most of our residential customers use our 15yd dumpsters. </section>
                            <footer className="post-card-footer">
                                <div className="post-card-footer-left">
                                    <span>Residential</span>
                                </div>
                                {/* <div className="post-card-footer-right">
                                    <div>readingTime</div>
                                </div> */}
                            </footer>
                          </div>

                          <div className="post-card" style={{padding:"10px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.35)"}}>
                            <header className="post-card-header">
                                <div style={{textAlign:"center", width:"auto", marginBottom:"10px", backgroundSize:"cover", height:""}} >
                                  <img src={p3}></img>
                                </div>
                                <div className="post-card-tags"> </div>
                                <h2 className="post-card-title">COMMERCIAL WASTE</h2>
                            </header>
                            <section style={{fontSize:"inherit"}} className="post-card-excerpt">Need a dumpster for your business, we've got you covered. From our 10 to our 40 yd dumpsters. Contact us to learn more about our recurrent customer program. </section>
                            <footer className="post-card-footer">
                                <div className="post-card-footer-left">
                                    <span>Business</span>
                                </div>
                                {/* <div className="post-card-footer-right">
                                    <div>readingTime</div>
                                </div> */}
                            </footer>
                          </div>

                          <div className="post-card" style={{padding:"10px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.35)"}}>
                            <header className="post-card-header">
                                <div style={{textAlign:"center", width:"auto", marginBottom:"10px", backgroundSize:"cover", height:""}} >
                                  <img src={p4}></img>
                                </div>
                                <div className="post-card-tags"> </div>
                                <h2 className="post-card-title">CONSTRUCTION WASTE</h2>
                            </header>
                            <section style={{fontSize:"inherit"}} className="post-card-excerpt">Dumpsters for construction sites. The 30yd dumpster is the our most popular dumpster for contractors. From demo to renovations, we cover all of your needs.</section>
                            <footer className="post-card-footer">
                                <div className="post-card-footer-left">
                                    <span>Contractors</span>
                                </div>
                                {/* <div className="post-card-footer-right">
                                    <div>readingTime</div>
                                </div> */}
                            </footer>
                          </div>
                          
                          <div className="post-card" style={{padding:"10px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.35)"}}>
                            <header className="post-card-header">
                                <div style={{textAlign:"center", width:"auto", marginBottom:"10px", backgroundSize:"cover", height:""}} >
                                  <img src={p5}></img>
                                </div>
                                <div className="post-card-tags"> </div>
                                <h2 className="post-card-title">WASTE REMOVAL</h2>
                            </header>
                            <section style={{fontSize:"inherit"}} className="post-card-excerpt">Dont have the time to do the work yourself. Ask about our waste removal program in your area.</section>
                            <footer className="post-card-footer">
                                <div className="post-card-footer-left">
                                    <span>Waste Removal</span>
                                </div>
                                {/* <div className="post-card-footer-right">
                                    <div>readingTime</div>
                                </div> */}
                            </footer>
                          </div>
                          


                          
                        </section>
                      </section>
                    </section>
                  </article>
                </div>
            </div>


        </SectionContainer>
      </section>
      

  );
};


export default Types;



