import React from 'react';
// import { Heading, Flex, Box, Text } from 'rebass/styled-components';
// import TextLoop from 'react-text-loop';
// import Section from 'gatsby-theme-mate/src/components/Section';
// import SocialLink from 'gatsby-theme-mate/src/components/SocialLink';
// import ScrollIcon from 'gatsby-theme-mate/src/components/ScrollIcon';
// import Triangle from 'gatsby-theme-mate/src/components/Triangle';
// import { useSiteQuery } from 'gatsby-theme-mate/src/queries/useSiteQuery';
// import { SECTION } from 'gatsby-theme-mate/src/utils/constants';
// import { getSectionHref } from 'gatsby-theme-mate/src/utils/helpers';
import Triangle from './Triangle';
import styled,{Heading} from 'styled-components';
import ScrollIcon from './ScrollIcon';
// import {Motion, spring, StaggeredMotion} from 'react-motion';
import TextLoop from 'react-text-loop'
// import { Heading, Flex, Box, Text } from 'rebass/styled-components';
import { Link } from 'gatsby';


const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto' };
const SectionContainer = styled.div`
  min-height: 100vh;
  min-width: 320px;
  max-width: 1366px;
  display: flex;
  margin: auto;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  padding: 5em 1em;
  scroll-behavior: smooth;

  @media (max-width: 400px) {
    padding: 2em 1em;
    justify-content: center;
    display:flex;
  }
`;
const Label = styled.h1`
  color: #007451;
  text-align: center;
  font-size: 24px;
  z-index: 0;
`
const ContactButton = styled.a`
  border-style: solid;
  border-width: 2px;
  border-color: #007451;
  color: #007451;
  z-index:-1;
  text-align: center;
  border-radius: 5px;
  font-size: 30px;
  padding: 10px;
  top: 50%;
  text-decoration: none;

`
const Project = () => {
//   const { name, roles, socialLinks, deterministic } = useSiteQuery();
  return (
      <section id="project" style={{ position: 'relative', marginBottom: '0px'}}>
          
          <>
    <Triangle
      color="#FFCC29"
      height={['50vh', '20vh']}
      width={['50vw', '50vw']}
    />

    <Triangle
      color="#007451"
      height={['10vh', '10vh']}
      width={['75vw', '70vw']}
    />

    <Triangle
      color="#81B214"
      height={['25vh', '20vh']}
      width={['100vw', '100vw']}
      position="top-right"
    />

    <Triangle
      color="#FFCC29"
      height={['20vh', '20vh']}
      width={['100vw', '100vw']}
      position="bottom-right"
    />
        
  </>

        <SectionContainer>
            {/* <div style={{"textAlign":"center", "margin":"20px", "zIndex":0}}>
            <ContactButton href='/contact'>Get a Dumpster</ContactButton>
            </div> */}
            
            {/* <ScrollIcon/>
            <Label style={{"font-size":34}}>Dumpsters Rental</Label>
            <Label>
              <TextLoop springConfig={{ stiffness: 180, damping: 7 }}>
              <div>(347) 988-6934</div>
              <div>10, 15, 20, 30 and 40 yd dumpsters</div>
              <div>(347) 988-6934</div>
              <div>Now Serving NYC and Long Island</div>
              <div>(347) 988-6934</div>
              <div>Dumpsters for all your needs!</div>
              <div>(347) 988-6934</div>
              <div>10, 15, 20, 30 and 40 yd dumpsters</div>
              <div>(347) 988-6934</div>
              <div>Reliable Service</div>
              <div>(347) 988-6934</div>
              <div>Junk Removal</div>
              </TextLoop>
            </Label> */}
            <div className='container'  style={{"marginTop":"30px", zIndex:0}}>

              {/* <main className="site-main"> */}
                {/* <div style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.6)",backgroundColor:"rgba(129,178,20,0.70)"}}className="container"> */}
                <div style={{}}className="container">

                  <article className="content" >
                    {/* <figure className="post-feature-image">
                      <img src="https://static.ghost.org/v4.0.0/images/welcome-to-ghost.png" alt="Start here for a quick overveiew of everything you need to know"/>
                    </figure> */}
                    <section className="post-full-content" style={{backgroundColor:"transparent",color:"black"}} >
                      <h4 className="content-title" style={{color:"black"}}><span style={{color:"black"}}></span>Need a dumpster for your project ?</h4>
                      <section className="content-body load-external-scripts">
                          <p style={{margin:"0"}}><strong>Not a problem</strong> <span style={{color:"black"}}>,At <strong>EXpressDump.com</strong></span> we offer many solutions for you. Our 15 yd dumpster is our most popular dumpster for residential projects. </p>
                          <p style={{margin:"0"}}>We make the process of renting a dumpster as simple as possible for you.</p>
                          <p>You will get a quote with the details of what is included, avoiding any unexpected charges on your bill</p>
                          <h4 style={{color:"black", marginBottom:"20px"}}>Take a look at the available sizes:</h4>
                          <Link className="site-nav-button" to="#sizes" style={{width:"300px", backgroundColor:"#81b214", color:"#fff", fontSize:"30px", border:"solid", textAlign:"center",borderColor:"#007451", borderWidth:"3px", borderRadius:"30px", padding:"15px 0px 10px 0px", fontWeight:"bold", opacity:"1"}}>Dumpster Sizes</Link>
                          <h4 style={{color:"black", marginBottom:"20px"}}>Questions?</h4>
                          <p style={{margin:"0"}}>If you are planning on doing some yard work, house cleanouts, renovations or any other projects and have some questions or need some insights, check our Blog.</p>
                          <Link className="site-nav-button" to="#blog" style={{width:"210px", backgroundColor:"#81b214", color:"#fff", fontSize:"30px", border:"solid", textAlign:"center",borderColor:"#007451", borderWidth:"3px", borderRadius:"30px", padding:"15px 0px 10px 0px", fontWeight:"bold", opacity:"1"}}>Blog Articles</Link>
                          <h4 style={{color:"black", marginBottom:"20px"}}>Contact Us or Fill out a dumpster request:</h4>
                          <span>One of our team members can assist you with your dumpsters request.</span>
                          <span>Call (929)-615-4980 to speak to one of our team members.</span>
                          <Link className="site-nav-button" to="#contact" style={{width:"270px", backgroundColor:"#81b214", color:"#fff", fontSize:"30px", border:"solid", textAlign:"center",borderColor:"#007451", borderWidth:"3px", borderRadius:"30px", padding:"15px 0px 10px 0px", fontWeight:"bold", opacity:"1"}}>Request Dumpster</Link>
                        <br></br>
                        {/* <hr><p>For now, you're probably just wondering what to do first. To help get you going as quickly as possible, we've populated your site with starter content (like this post!) covering all the key concepts and features of the product.</p><p>You'll find an outline of all the different topics below, with links to each section so you can explore the parts that interest you most.</p><p>Once you're ready to begin publishing and want to clear out these starter posts, you can delete the "Ghost" staff user. Deleting an author will automatically remove all of their posts, leaving you with a clean blank canvas.</p><h2 id="your-guide-to-ghost">Your guide to Ghost</h2><ul><li><a href="http://localhost:2368/design/">Customizing your brand and site settings</a></li><li><a href="http://localhost:2368/write/">Writing &amp; managing content, an advanced guide for creators</a></li><li><a href="http://localhost:2368/portal/">Building your audience with subscriber signups</a></li><li><a href="http://localhost:2368/sell/">Selling premium memberships with recurring revenue</a></li><li><a href="http://localhost:2368/grow/">How to grow your business around an audience</a></li><li><a href="http://localhost:2368/integrations/">Setting up custom integrations and apps</a></li></ul><p>If you get through all those and you're hungry for more, you can find an extensive library of content for creators over on <a href="https://ghost.org/blog/">the Ghost blog</a>.</p><hr><h2 id="getting-help">Getting help</h2><p>If you need help, <a href="https://ghost.org/pricing/">Ghost(Pro)</a> customers can always reach our full-time support team by clicking on the <em>Ghost(Pro)</em> link inside their admin panel.</p><p>If you're a developer working with the codebase in a self-managed install, check out our <a href="https://forum.ghost.org">developer community forum</a> to chat with other users.</p><p>Have fun!</p> */}
                      </section>
                    </section>
                  </article>
                </div>
              {/* </main> */}
            </div>


        </SectionContainer>
      </section>
      

  );
};


export default Project;



