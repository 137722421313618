import React from 'react';
import Triangle from './Triangle';
import styled,{Heading} from 'styled-components';


const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto' };
const SectionContainer = styled.div`
  min-height: 100vh;
  min-width: 320px;
  max-width: 1366px;
  display: flex;
  margin: auto;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  padding: 5em 1em;
  scroll-behavior: smooth;

  @media (max-width: 400px) {
    padding: 2em 1em;
    justify-content: center;
    display:flex;
  }
`;

const cities = ["New York","Nassau","Yonkers","Manhattan","Brooklyn", "Queens","Bronx"]
const ServiceAreas = () => {
  return (
    <section id="serviceAreas" style={{ position: 'relative', marginBottom: '0px'}}>
          
  <>
    <Triangle
      color="#FFCC29"
      height={['50vh', '20vh']}
      width={['50vw', '50vw']}
    />

    <Triangle
      color="#007451"
      height={['20vh', '40vh']}
      width={['75vw', '70vw']}
    />

    <Triangle
      color="#81B214"
      height={['25vh', '20vh']}
      width={['100vw', '100vw']}
      position="top-right"
    />

    <Triangle
      color="#FFCC29"
      height={['20vh', '20vh']}
      width={['100vw', '100vw']}
      position="bottom-right"
    />
        
  </>

        <SectionContainer>



<div className='container' style={{"marginTop":"0px", zIndex:0}}>
                <div className="container">
                  <article className="content" >
                    <section className="post-full-content" >
                      <h4 className="content-title" style={{textAlign:"center", marginTop:"10%" }}><span style={{color:"#81B214"}}>S</span>ervice Areas</h4>
                      <section className="content-body load-external-scripts">
                        <section className="post-feed" style={{ alignSelf:"center"}}>


                          {cities.map(x=>
                          <div className="post-card" style={{padding:"10px",boxShadow: "0 4px 8px 0 rgba(0,0,0,0.35)",backgroundColor:'green', borderRadius:'15px'}}>
                            <header className="post-card-header" >
                                <div style={{textAlign:"center", width:"auto", marginBottom:"10px", backgroundSize:"cover", height:"auto"}}>
                                </div>
                                <div className="post-card-tags"> </div>
                                <h2 style={{textAlign:'center', color:'#fff'}} className="post-card-title">{x}</h2>

                            </header>
                            <footer className="post-card-footer">
                                <div className="post-card-footer-left">
                                </div>
                            </footer>
                          </div>)}
                          
                          
                        </section>
                      </section>
                    </section>
                  </article>
                </div>
            </div>
        </SectionContainer>
      </section>
      
  );
};


export default ServiceAreas;



